{
    "name": "wl-svsdb",
    "version": "1.0.0",
    "scripts": {
        "ng": "ng",
        "start": "npm run start-basic -- --ssl --ssl-key ./certificates/ssl-dev/key.pem --ssl-cert ./certificates/ssl-dev/cert.pem",
        "start-basic": "ng serve --disable-host-check --host 0.0.0.0",
        "build": "ng build --c production",
        "build:sourcemaps": "npm run build && npm run sentry:sourcemaps",
        "build:stats": "ng build --stats-json",
        "analyze": "webpack-bundle-analyzer dist/stats.json",
        "watch": "ng build --watch --configuration development",
        "prebuild": "node update-version.js",
        "server:cypress": "DB_CYPRESS=true npm run server",
        "server": "nodemon --icu-data-dir=node_modules/full-icu server/app.www.js --watch server/",
        "service": "node server/app.www.js",
        "sentry-cli": "sentry-cli",
        "openssl:init": "npm run openssl:create && npm run openssl:trust",
        "openssl:create": "openssl req -nodes -x509 -newkey rsa:4096 -keyout ./certificates/ssl-dev/key.pem -out ./certificates/ssl-dev/cert.pem -config ./certificates/ssl-dev/certificate.cnf -days 3650",
        "openssl:trust": "sudo security add-trusted-cert -d -r trustRoot -k /Library/Keychains/System.keychain ./certificates/ssl-dev/cert.pem",
        "test": "ng test",
        "lint": "npx eslint 'src/**/*.{js,jsx,ts,tsx,html,css,scss}' --quiet --fix",
        "format": "npx prettier 'src/**/*.{js,jsx,ts,tsx,html,css,scss}' --write",
        "cypress:open": "cypress open",
        "sentry:sourcemaps": "sentry-cli sourcemaps inject --org tactile --project wl-svsdb ./dist && sentry-cli sourcemaps upload --org tactile --project wl-svsdb ./dist"
    },
    "engines": {
        "node": "18.x"
    },
    "private": true,
    "dependencies": {
        "@abacritt/angularx-social-login": "2.2",
        "@angular/material": "^16.2.12",
        "@fullcalendar/list": "^6.1.10",
        "@gumlet/gif-resize": "^1.3.1",
        "@pqina/angular-pintura": "^9.0.2",
        "@pqina/filepond-plugin-image-editor": "^9.0.2",
        "@pqina/pintura": "^8.60.10",
        "@sentry/node": "^5.30.0",
        "@sentry/tracing": "^6.19.7",
        "@woodl/crud": "^1.1.2",
        "@woodl/export": "^3.0.0",
        "@woodl/extend-smart": "^1.0.3",
        "@woodl/google-api": "^1.1.0",
        "@woodl/hmg-models": "^2.2.10",
        "@woodl/import": "^2.0.0",
        "@woodl/models": "^2.0.8",
        "@woodl/orderwork-calculate": "^1.4.6",
        "@woodl/ssl-renew": "^1.1.1",
        "acme-client": "^4.2.5",
        "app-root-path": "^3.1.0",
        "aws-sdk": "^2.1236.0",
        "axios": "^0.21.4",
        "bcrypt": "^5.1.0",
        "body-parser": "^1.20.1",
        "bootswatch": "^5.2.2",
        "bson-objectid": "^2.0.4",
        "change-case": "^4.1.2",
        "compression": "^1.7.4",
        "cors": "^2.8.5",
        "cryptr": "^6.0.3",
        "csvtojson": "^2.0.10",
        "dayjs": "^1.11.5",
        "debug": "^4.3.4",
        "delighted": "^2.1.0",
        "digitalocean": "^1.2.1",
        "dns": "^0.2.2",
        "easyinvoice": "^3.0.44",
        "email-addresses": "^3.1.0",
        "email-validator": "^2.0.4",
        "emailjs-mime-builder": "^2.0.5",
        "escape-string-regexp": "^4.0.0",
        "express": "^4.18.2",
        "express-async-errors": "^3.1.1",
        "fast-csv": "^4.3.6",
        "full-icu": "^1.5.0",
        "gmail-api-parse-message": "^2.1.2",
        "googleapis": "^67.1.1",
        "googlemaps": "^1.12.0",
        "handlebars": "^4.7.7",
        "html-to-text": "^5.1.1",
        "ical-generator": "^1.15.4",
        "inline-css": "^2.6.3",
        "ip": "^1.1.8",
        "json2csv": "^5.0.7",
        "jsonwebtoken": "^8.5.1",
        "jszip": "^3.10.1",
        "juice": "^8.1.0",
        "lodash": "^4.17.21",
        "mailgun-js": "^0.22.0",
        "marked": "^12.0.0",
        "migrate-mongo": "^8.2.3",
        "moment": "^2.29.4",
        "moment-timezone": "^0.5.38",
        "mongoose": "^6.8.3",
        "morgan": "^1.10.0",
        "multer": "^1.4.4",
        "node-fetch": "^2.6.7",
        "papaparse": "^5.3.2",
        "pdfjs-dist": "^3.5.141",
        "randomstring": "^1.2.2",
        "request": "^2.88.2",
        "request-promise": "^4.2.6",
        "sharp": "^0.31.1",
        "tiny-async-pool": "^2.1.0",
        "tippy.js": "^6.3.7",
        "util": "^0.12.5",
        "uuid": "^8.3.2"
    },
    "devDependencies": {
        "@angular-devkit/build-angular": "^17.3.5",
        "@angular-eslint/builder": "^17.3.0",
        "@angular-eslint/eslint-plugin": "^17.3.0",
        "@angular-eslint/eslint-plugin-template": "^17.3.0",
        "@angular-eslint/schematics": "^17.3.0",
        "@angular-eslint/template-parser": "^17.3.0",
        "@angular/animations": "^17.3.5",
        "@angular/cli": "^17.3.5",
        "@angular/common": "^17.3.5",
        "@angular/compiler": "^17.3.5",
        "@angular/compiler-cli": "^17.3.5",
        "@angular/core": "^17.3.5",
        "@angular/forms": "^17.3.5",
        "@angular/language-service": "^17.3.5",
        "@angular/platform-browser": "^17.3.5",
        "@angular/platform-browser-dynamic": "^17.3.5",
        "@angular/router": "^17.3.5",
        "@ckeditor/ckeditor5-angular": "^6.0.1",
        "@ckeditor/ckeditor5-build-classic": "^38.0.1",
        "@fortawesome/angular-fontawesome": "^0.14.1",
        "@fortawesome/fontawesome": "^1.2.0-6",
        "@fortawesome/fontawesome-pro": "^6.5.2",
        "@fortawesome/fontawesome-svg-core": "^6.5.2",
        "@fortawesome/free-brands-svg-icons": "^6.5.2",
        "@fortawesome/pro-light-svg-icons": "^6.5.2",
        "@fortawesome/pro-regular-svg-icons": "^6.5.2",
        "@fortawesome/pro-solid-svg-icons": "^6.5.2",
        "@fortawesome/pro-thin-svg-icons": "^6.5.2",
        "@fullcalendar/angular": "^6.1.10",
        "@fullcalendar/bootstrap": "^6.1.11",
        "@fullcalendar/core": "^6.1.11",
        "@fullcalendar/daygrid": "^6.1.11",
        "@fullcalendar/interaction": "^6.1.11",
        "@fullcalendar/resource": "^6.1.11",
        "@fullcalendar/resource-common": "^5.11.5",
        "@fullcalendar/resource-timegrid": "^6.1.11",
        "@fullcalendar/resource-timeline": "^6.1.11",
        "@fullcalendar/timegrid": "^6.1.11",
        "@ng-select/ng-select": "^12.0.7",
        "@sentry/angular-ivy": "^7.112.1",
        "@sentry/cli": "^2.31.0",
        "@swimlane/ngx-charts": "^20.4.1",
        "@types/lodash": "^4.14.186",
        "@types/node": "^13.13.52",
        "@typescript-eslint/eslint-plugin": "^6.14.0",
        "@typescript-eslint/parser": "^6.14.0",
        "ang-jsoneditor": "^3.1.0",
        "angularx-qrcode": "^17.0.0",
        "chart.js": "^3.9.1",
        "chartjs-plugin-annotation": "^2.1.2",
        "chartjs-plugin-autocolors": "^0.2.2",
        "chartjs-plugin-datalabels": "^2.2.0",
        "ckeditor5": "^38.0.1",
        "core-js": "^3.25.5",
        "cypress": "^10.10.0",
        "cypress-file-upload": "^5.0.8",
        "cypress-fill-command": "^1.0.2",
        "cypress-plugin-tab": "^1.0.5",
        "cypress-watch-and-reload": "^1.10.3",
        "eslint": "^8.23.1",
        "eslint-config-prettier": "^8.5.0",
        "eslint-plugin-prettier": "^4.2.1",
        "eslint-plugin-unused-imports": "^3.0.0",
        "filepond": "^4.30.4",
        "filepond-plugin-file-poster": "^2.5.1",
        "filepond-plugin-file-validate-size": "^2.2.8",
        "filepond-plugin-file-validate-type": "^1.2.8",
        "filepond-plugin-image-crop": "^2.0.6",
        "filepond-plugin-image-edit": "^1.6.3",
        "filepond-plugin-image-exif-orientation": "^1.0.11",
        "filepond-plugin-image-preview": "^4.6.11",
        "filepond-plugin-image-resize": "^2.0.10",
        "filepond-plugin-image-transform": "^3.8.7",
        "filepond-plugin-image-validate-size": "^1.2.7",
        "filepond-polyfill": "^1.0.4",
        "jsoneditor": "^9.10.2",
        "ng2-charts": "^4.1.1",
        "ng2-currency-mask": "^13.0.3",
        "ngx-bootstrap": "^12.0.0",
        "ngx-clipboard": "^16.0.0",
        "ngx-filepond": "^7.0.1",
        "ngx-papaparse": "^4.0.4",
        "ngx-spinner": "^16.0.2",
        "ngx-toastr": "^16.0.2",
        "nodemon": "^2.0.20",
        "prettier": "^2.7.1",
        "rxjs": "^7.5.7",
        "signature_pad": "^4.1.5",
        "typescript": "5.4.5",
        "webpack-bundle-analyzer": "^3.9.0",
        "zone.js": "^0.14.4"
    }
}
